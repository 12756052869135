import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { MathJax } from 'better-react-mathjax';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Pavement Foundation Engineering`}</h1>
    <h2>{`Books`}</h2>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1CguYpkakzR1ZpzNrrAlwbtUl3wxHEGu7"
      }}>{`Download: AASHTO Guide for design of pavement structures 1993`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1KNLWqB_ZdYhBSf8U4kJ9TmXmkbbD7An5"
      }}>{`Download: Highway Pavement structural design (Rigid and Flexible pavement guide nomograms)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PVUJPQzNqWE9OTms"
      }}>{`Download: Foundation analysis and design by Joseph E. Bowles 5th Ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1PBQ3AMNKwT-HE0uqi2yBRSBIIKtE9b68"
      }}>{`Download: Foundation design, principles and practices by Donald P. Coduto 2 Ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PNXZncjVJbkJ3Ymc"
      }}>{`Download: Foundation design and construction by MJ Tomlinson 7th Ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1z9CRWhicoGAIusmCitcRN75z4RjnEG1Z"
      }}>{`Download: Overseas road note 31 `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1v3mDdIYLbZvz_PX5o29xtVPBCPkSwJqZ"
      }}>{`Download: Pavement Engineering Principles and Practices by Rajib B. Malik and Tahar El. Korchi 2 Ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1Rz4m5B4A4hjQ5OlO4gDjLKapeUxX5Uib"
      }}>{`Download: The handbook of highway engineering by T. F. Fwa`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=12L9n58wAgymG_tAqu6C98o6jGlrQ4dV_"
      }}>{`Download: Transportation infrastructure engineering A multimodal integration By Lester A. Hoel, Nicholas J. Garber, Adel W. Sadek`}</a></p>
    <h2>{`Lecture slides`}</h2>
    <h3>{`Pavement Engineering`}</h3>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/1.-Pavements-Introduction.pdf"
      }}>{`1. Pavements (Introduction)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/2.-Pavements.pdf"
      }}>{`2. Pavements`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/3.-CBR.pdf"
      }}>{`3. CBR`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/4.-Design-of-Pavements.pdf"
      }}>{`4. Design of Pavements`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/5.-Traffic-Terms-and-Determination-of-ESAL.pdf"
      }}>{`5. Traffic Terms and Determination of ESAL`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/6._Road_Note-31.pdf"
      }}>{`6._Road_Note-31`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/7._AASHTO_Flexible_Pavement_Design_Method.pdf"
      }}>{`7._AASHTO_Flexible_Pavement_Design_Method`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/8._AASHTO_Rigid_Pavement_Design_Method_Modified_.pdf"
      }}>{`8.`}<em parentName="a">{`AASHTO_Rigid_Pavement_Design_Method_Modified`}</em></a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/8.1-joint-design.pdf"
      }}>{`8.1 joint design`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/9._Defects_in_Pavements.pdf"
      }}>{`9._Defects_in_Pavements`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/10._Maintenance_and_Rehabilitation_of_Pavements_.pdf"
      }}>{`10.`}<em parentName="a">{`Maintenance_and_Rehabilitation_of_Pavements`}</em></a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/11._Road_Recycling_Methods_and_Failure_Criteria_for_Pavements.pdf"
      }}>{`11._Road_Recycling_Methods_and_Failure_Criteria_for_Pavements`}</a></p>
    <h3>{`Foundation Engineering`}</h3>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/1._Foundation_Engineering_Basics_of_Soil_Formation_and_Classification.pdf"
      }}>{`1._Foundation_Engineering_Basics_of_Soil_Formation_and_Classification`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/2._Types_of_Foundations.pdf"
      }}>{`2._Types_of_Foundations`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/3._Foundation_Design_Requirements.pdf"
      }}>{`3._Foundation_Design_Requirements`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/4._Bearing_Capacity_of_Shallow_Foundations.pdf"
      }}>{`4._Bearing_Capacity_of_Shallow_Foundations`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/5._Elastic_or_Immediate_Settlement.pdf"
      }}>{`5._Elastic_or_Immediate_Settlement`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/6._Bearing_Capacity_from_other_methods.pdf"
      }}>{`6._Bearing_Capacity_from_other_methods`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/7._Combined_Footing.pdf"
      }}>{`7._Combined_Footing`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/8._Difficult_Soils.pdf"
      }}>{`8._Difficult_Soils`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/9._Classification_of_Piles_and_Pile_Capacity.pdf"
      }}>{`9._Classification_of_Piles_and_Pile_Capacity`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/10._Pile_Load_Test_Pile_Group_and_Negative_Skin_Friction_Modified_.pdf"
      }}>{`10.`}<em parentName="a">{`Pile_Load_Test_Pile_Group_and_Negative_Skin_Friction_Modified`}</em></a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/11._Pile_Load_Capacity_in_Cohesionless_Soils.pdf"
      }}>{`11._Pile_Load_Capacity_in_Cohesionless_Soils`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/12._Pile_Capacity_in_Cohesive_Soils_and_Uplift_Resistance_of_Piles.pdf"
      }}>{`12._Pile_Capacity_in_Cohesive_Soils_and_Uplift_Resistance_of_Piles`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Cement-Stabilization-Revised.pdf"
      }}>{`13. Cement Stabilization Revised`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/DIFFICULT-SOILS-Detailed-Notes.pdf"
      }}>{`14. DIFFICULT SOILS-Detailed Notes`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/6.-Grading-of-Materials.ppt"
      }}>{`15. Grading of Materials`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Soil-Works.ppt"
      }}>{`16. Soil Works`}</a></p>
    <h2>{`Lab manual`}</h2>
    <h3>{`Pavement Design including`}</h3>
    <p>{`Design of flexible pavement by group index method
Design of flexible pavement by CBR Method
Design of Rigid Pavement by Westergaard method`}</p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/download/pavement-and-foundation-engineering-lab-manual/"
      }}>{`Download lab manual: Pavement and foundation engineering lab manual. prepared by Usman Iftikhar`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      